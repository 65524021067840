<template>
  <router-link :to="to">
    <div class="logo-container">
      <v-img
        contain
        src="@/assets/images/tereno-logo.png"
        width="200"
        class="tereno-image"
      />
      <v-img
        contain
        src="@/assets/images/doi-logo.svg"
        :width="30"
        class="doi-image"
      />
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'TdoiLogo',
  props: {
    to: {
      type: String,
      required: false,
    },
  },
}
</script>

<style scoped>
.logo-container {
  position: relative;
  width: 215px;
  height: 80px;
}

.tereno-image {
  position: relative;
  top: 15px;
  left: 0;
}

.doi-image {
  position: relative;
  top: -28px;
  left: 180px;
}
</style>

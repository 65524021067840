let timeoutId

const stopTimer = () => {
  timeoutId && clearTimeout(timeoutId)
}

const api = (vue) => {
  const state = vue.observable({
    loading: undefined,
    details: undefined
  })

  return {
    get isLoading () {
      return state.loading
    },

    get details () {
      return state.details
    },

    set details (value) {
      state.details = value
    },

    startLoading () {
      stopTimer()
      // if the timer is stopped before it runs out, we are not in loading state
      timeoutId = setTimeout(() => (state.loading = true), 100)
    },

    stopLoading () {
      stopTimer()
      state.loading = false
      state.title = undefined
      state.details = undefined
      timeoutId = undefined
    },
  }
}

export default api

import Vue from 'vue'
import App from './app.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/google-maps'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import environment from '@/shared/utils/environment'

if (environment.isProduction) {
  Bugsnag.start({
    apiKey: 'd70358a54de0e703638745f6ddc1d1fa',
    plugins: [new BugsnagPluginVue()]
  })
  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
}

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')

const HEADER_KEY_AUTHORIZATION = 'Authorization'

const HEALTH_CHECK_URL = 'api/health-check'

const LOGIN_URL = 'api/login'
const LOGOUT_URL = 'api/logout'

const DOIS_URL = 'api/dois'
const USERS_URL = 'api/users'

const DATACITE_RANDOM_DOI_URL = 'api/datacite/random-doi'
const DATACITE_CLIENTS_URL = 'api/datacite/clients'
const DATACITE_CLIENT_STATISTICS_URL = 'api/datacite/client-statistics'

const SEARCH_GEONAMES_URL = 'api/geonames'

const api = (environment, token, axiosTemplate) => {
  const createAuthorization = () => {
    const tokenValue = token.getToken()?.access_token
    return tokenValue ? `Bearer ${tokenValue}` : ''
  }

  const configuration = {
    baseURL: environment.apiUrl,
    timeout: 10000,
    headers: { [HEADER_KEY_AUTHORIZATION]: createAuthorization() }
  }
  const axios = axiosTemplate.create(configuration)

  return {
    createCancelToken () {
      return axiosTemplate.CancelToken.source()
    },

    async healthCheck () {
      return axios.get(HEALTH_CHECK_URL)
    },

    async login (code) {
      const nothingAvailableForLogin = !code && !token.getToken()
      if (nothingAvailableForLogin) return
      // console.log('login', token.getToken())
      const result = await axios.post(LOGIN_URL, { code, token: token.getToken() })
      if (result?.data) {
        const now = Date.now()
        const expires = result.data.token.expires
        if (now < expires) {
          setTimeout(() => {
            this.login()
          }, Math.max(0, expires - now - 60 * 1000)) // fire timeout one minute before expire
          token.setToken(result.data.token, true)
          axios.defaults.headers[HEADER_KEY_AUTHORIZATION] = createAuthorization()
          return result.data.user
        }
      }
    },

    logout () {
      token.removeToken()
      // do not wait for logout
      axios.post(LOGOUT_URL)
      // remove authorization after logout
      delete axios.defaults.headers[HEADER_KEY_AUTHORIZATION]
    },

    getDataciteClients () {
      return axios.get(DATACITE_CLIENTS_URL)
    },

    getDataciteClientStatistics (clientId) {
      return axios.get(DATACITE_CLIENT_STATISTICS_URL, { params: { clientId } })
    },

    getDois (params) {
      return axios.get(DOIS_URL, { params })
    },

    getDoi (doi) {
      return axios.get(`${DOIS_URL}/${encodeURIComponent(doi)}`)
    },

    getRandomDoi () {
      return axios.get(DATACITE_RANDOM_DOI_URL)
    },

    updateDoi (data) {
      return axios.put(`${DOIS_URL}/${encodeURIComponent(data.data.attributes.doi)}`, data)
    },

    createDoi (data) {
      return axios.post(DOIS_URL, data)
    },

    deleteDoi (doi) {
      return axios.delete(`${DOIS_URL}/${encodeURIComponent(doi)}`)
    },

    getUsers () {
      return axios.get(USERS_URL)
    },

    searchGeonames (value, cancelToken) {
      return axios.get(`${SEARCH_GEONAMES_URL}?q=${encodeURIComponent(value)}`, { cancelToken })
    }
  }
}

export default api

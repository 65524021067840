const api = (HOME_ROUTE) => ({
  clientId: process.env.VUE_APP_DATACITE_CLIENT_ID,
  authClientId: process.env.VUE_APP_OAUTH_CLIENT_ID,

  // you can add additional parameters after redirect uri that are also transfered back
  get authorizationCodeUrl () {
    return `${process.env.VUE_APP_OAUTH_AUTH_URL}?client_id=${process.env.VUE_APP_OAUTH_CLIENT_ID}&response_type=code&redirect_uri=${document.location.origin}&scope=email,profile`
    // : { name: HOME_ROUTE, query: { code: 'development' } } does not work
  },

  apiUrl: process.env.VUE_APP_API_URL,

  isProduction: process.env.NODE_ENV === 'production',

  version: process.env.VUE_APP_VERSION,

  landingPageBaseUrl: process.env.VUE_APP_LANDING_PAGE_BASE_URL,

  dataciteApiDoisUrl: process.env.VUE_APP_DATACITE_API_DOIS_URL
})

export default api

import Vue from 'vue'
import VueRouter from 'vue-router'
import account from '@/shared/models/account'
import loading from '@/shared/models/loading'

import {
  HOME_ROUTE, CREATE_DOI_ROUTE, STATISTICS_DOI_ROUTE, NOT_FOUND_ROUTE, EXTERNAL_DOI_ROUTE
} from './route-names'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: `/${HOME_ROUTE}`,
    name: HOME_ROUTE,
    component: () => import(/* webpackChunkName: "list-doi" */ '@/views/list-doi-view.vue'),
    props: route => ({ code: route.query.code })
  },
  {
    path: `/${CREATE_DOI_ROUTE}`,
    name: CREATE_DOI_ROUTE,
    component: () => import(/* webpackChunkName: "create-doi" */ '@/views/create-doi-view/create-doi-view.vue'),
    props: route => ({ ...route.query })
  },
  {
    path: `/${EXTERNAL_DOI_ROUTE}`,
    name: EXTERNAL_DOI_ROUTE,
    component: () => import(/* webpackChunkName: "external-doi" */ '@/views/external-doi-view.vue'),
    props: route => ({ ...route.query })
  },
  {
    path: `/${STATISTICS_DOI_ROUTE}`,
    name: STATISTICS_DOI_ROUTE,
    component: () => import(/* webpackChunkName: "statistics-doi" */ '@/views/statistics-doi-view.vue'),
  },
  {
    path: '*',
    name: NOT_FOUND_ROUTE,
    component: () => import(/* webpackChunkName: "error" */ '@/views/not-found-view.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  // console.log('beforeEach', to.name, from.name)
  // not if we have a code to login, that is done in home
  if (!account.loggedIn && !to.query.code) {
    loading.details = 'Automatic login'
    loading.startLoading()
    await account.login()
    loading.stopLoading()
  }
  next()
})

export default router

const api = {
  isArray (value) {
    return value instanceof Array
  },

  isObject (value) {
    return value != null && value instanceof Object
  },

  isString (value) {
    return typeof value === 'string'
  },
  isIterable (value) {
    return !!value[Symbol.iterator]
  },

  downcaseFirst ([firstLetter, ...rest] = []) {
    return firstLetter ? `${firstLetter.toLowerCase()}${rest.join('')}` : undefined
  },

  sortBy (fieldName, desc) {
    const less = desc ? 1 : -1
    return ({ [fieldName]: first }, { [fieldName]: second }) => {
      if (first === second) {
        return 0
      }
      if (first == null) {
        return less
      }
      if (second == null) {
        return -less
      }
      return first < second ? less : (first > second ? -less : 0)
    }
  }
}

export default api

const api = (vue, backendServices, applicationState) => {
  const state = vue.observable({
    loggedIn: false,
    userId: undefined,
    username: '',
    email: ''
  })

  const clearState = () => {
    state.loggedIn = false
    state.userId = undefined
    state.username = ''
    state.email = ''
  }

  return {
    get hasEmail () {
      return !!state.email
    },

    get userId () {
      return state.userId
    },

    get username () {
      return state.username
    },

    get loggedIn () {
      return state.loggedIn
    },

    async login (code) {
      try {
        const user = await backendServices.login(code)
        if (user) {
          state.loggedIn = true
          state.userId = user.id
          state.username = user.name
          state.email = user.email
          !state.email && applicationState.addWarning('The logged-in user cannot receive any e-mails from the system!')
        }
      } catch (exception) {
        applicationState.addError(exception.toString())
        clearState()
      }
    },

    logout () {
      backendServices.logout()
      clearState()
    }
  }
}

export default api

<template>
  <v-app>
    <tdoi-progress-overlay/>
    <v-app-bar app>
      <tdoi-logo :to="HOME_ROUTE" />
      <v-row class="ml-5">
        <v-btn
          v-for="view in views"
          :key="view.route"
          :to="view.route"
          text
        >
          {{ view.name }}
        </v-btn>
        <v-spacer/>
        <user-menu-component
          :logged-in="loggedIn"
          :username="username"
          @login="login"
          @logout="logout"
        />
      </v-row>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <footer-component :version="version"/>
  </v-app>
</template>

<script>
import utils from '@/shared/utils/utils'
import environment from '@/shared/utils/environment'
import account from '@/shared/models/account'
import loading from '@/shared/models/loading'
import backendServices from '@/shared/services/backend-services'
import { HOME_ROUTE, EXTERNAL_DOI_ROUTE, STATISTICS_DOI_ROUTE } from '@/router/route-names'
import TdoiProgressOverlay from '@/shared/components/tdoi-progress-overlay'
import TdoiLogo from '@/shared/components/tdoi-logo'
import { components } from 'vue-component-library'

const VIEWS = [
  { route: HOME_ROUTE, name: 'DOIs' },
  { route: EXTERNAL_DOI_ROUTE, name: 'GFZ TDOIS' },
  { route: STATISTICS_DOI_ROUTE, name: 'Statistics' },
]
const { FooterComponent } = components
const { UserMenuComponent } = components

export default {
  name: 'App',
  components: { TdoiProgressOverlay, UserMenuComponent, TdoiLogo, FooterComponent },
  data () {
    return {
      backendVersion: undefined,
      frontendVersion: undefined
    }
  },
  computed: {
    version () {
      return `FE ${this.frontendVersion}/BE ${this.backendVersion} (${process.env.NODE_ENV})`
    },
    loggedIn () {
      return account.loggedIn
    },
    username () {
      return account.username
    }
  },
  async created () {
    this.HOME_ROUTE = HOME_ROUTE
    this.views = VIEWS
    this.frontendVersion = environment.version
    const { data } = await backendServices.healthCheck()
    this.backendVersion = data.version
  },
  methods: {
    login () {
      loading.details = 'Redirecting to login ...'
      loading.startLoading()
      this.navigateTo(environment.authorizationCodeUrl)
    },
    logout () {
      account.logout()
    },

    navigateTo (route) {
      if (utils.isString(route)) {
        window.location.href = route
      } else {
        this.$router.push(route)
      }
    }
  }
}
</script>

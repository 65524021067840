<template>
  <v-overlay z-index="6" :value="loading">
    <v-row>
      <div class="ma-2">{{ details }}</div>
    </v-row>
    <v-row justify="center">
      <v-progress-circular
        v-if="loading"
        class="loading"
        color="info"
        indeterminate
      />
    </v-row>
  </v-overlay>
</template>

<script>
import loading from '@/shared/models/loading'

export default {
  name: 'TdoiProgressOverlay',
  computed: {
    details () {
      return loading.details
    },
    loading () {
      return loading.isLoading
    }
  }
}
</script>
